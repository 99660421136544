import * as __WEBPACK_EXTERNAL_MODULE__daangn_car_big_picture__ from "@daangn/car-big-picture";
import * as __WEBPACK_EXTERNAL_MODULE_constate__ from "constate";
import * as __WEBPACK_EXTERNAL_MODULE_react__ from "react";
import * as __WEBPACK_EXTERNAL_MODULE_ts_pattern__ from "ts-pattern";
const useUploadImage = ({ onError, uploadImageFn })=>{
    const callbacksRef = (0, __WEBPACK_EXTERNAL_MODULE_react__.useRef)({});
    const [uploadingCount, setUploadingCount] = (0, __WEBPACK_EXTERNAL_MODULE_react__.useState)(0);
    const uploadImages = async (id, files)=>{
        setUploadingCount((pv)=>pv + files.length);
        for (const file of files)try {
            const { image } = await (0, __WEBPACK_EXTERNAL_MODULE__daangn_car_big_picture__.readImage)(file);
            const compressed = await (0, __WEBPACK_EXTERNAL_MODULE__daangn_car_big_picture__.imageToBlob)({
                image,
                file
            });
            const uploadedImage = await uploadImageFn({
                blob: compressed,
                mimeType: file.type,
                size: {
                    width: image.width,
                    height: image.height
                }
            });
            callbacksRef?.current?.[id]?.({
                type: 'success',
                payload: {
                    image: {
                        id: uploadedImage.id,
                        url: uploadedImage.url
                    }
                }
            });
        } catch (e) {
            onError?.(e);
            callbacksRef?.current?.[id]?.({
                type: 'failed',
                payload: {
                    e: e,
                    file
                }
            });
        } finally{
            setUploadingCount((pv)=>--pv);
        }
        callbacksRef?.current?.[id]?.({
            type: 'completed'
        });
    };
    const subscribeImageUploads = (0, __WEBPACK_EXTERNAL_MODULE_react__.useCallback)((id_0, callback)=>{
        callbacksRef.current = {
            ...callbacksRef.current,
            [id_0]: callback
        };
        return ()=>{
            callbacksRef.current = {
                ...callbacksRef.current,
                [id_0]: void 0
            };
        };
    }, []);
    const dispatch = (id_1, action)=>{
        (0, __WEBPACK_EXTERNAL_MODULE_ts_pattern__.match)(action).with({
            type: 'upload-image'
        }, (v)=>uploadImages(id_1, v.payload.files)).exhaustive();
    };
    return {
        isUploading: uploadingCount > 0,
        dispatch,
        subscribeImageUploads
    };
};
const [UploadImageContextProvider, useUploadImageContext] = (0, __WEBPACK_EXTERNAL_MODULE_constate__["default"])(useUploadImage);
export { UploadImageContextProvider, useUploadImageContext };
