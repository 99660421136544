/* eslint-disable @typescript-eslint/naming-convention */

import {
  type BigPictureDesc,
  type BigPicturePaths,
  type RequestUploadUrlInput,
  type UploadFileToS3Input,
  getImageExtension,
} from '@daangn/car-big-picture';
import { getRandomHex } from '@daangn/car-utils/string';
import axios from 'axios';

import CONFIG from '@/constants/config';
import { getInfo } from '@/sdk/bridge/info';

async function requestUploadUrl({ mimeType, size }: RequestUploadUrlInput) {
  const pathname: BigPicturePaths = 'api/v2/request_upload_url';
  const url = `${CONFIG.BIGPICTURE_HOST}/${pathname}`;
  const { app, region, user } = await getInfo();
  const filepath = `articles/${getRandomHex()}_${Date.now()}.${getImageExtension(mimeType)}`;

  const data: BigPictureDesc['api/v2/request_upload_url']['request'] = {
    user_id: user.id!,
    service: 'car',
    type: 'article',
    content_type: mimeType,
    filepath,
    ...size,
  };

  const headers: BigPictureDesc['api/v2/request_upload_url']['headers'] & Record<string, any> = {
    'Content-Type': 'application/json',
    'X-Auth-Token': user?.authToken,
    'X-REGION-ID': region.id,
    'X-User-Agent': app.userAgent,
  };

  try {
    const res = await axios
      .post(url, JSON.stringify(data), {
        headers,
      })
      .then((res) => res.data);

    if (res.error) {
      throw res.error;
    }
    return res.data.image as BigPictureDesc['api/v2/request_upload_url']['response'];
  } catch (e) {
    throw e;
  }
}

async function uploadFileToS3({ url, blob, mimeType, imageId }: UploadFileToS3Input) {
  const headers: BigPictureDesc['api/v2/upload_file_to_s3']['headers'] = {
    'Content-Type': mimeType,
    'x-amz-meta-id': imageId,
  };
  const config = {
    headers,
  };
  const body: BigPictureDesc['api/v2/upload_file_to_s3']['request'] = blob;
  try {
    const { data } = await axios.put(url, body, config);
    return data;
  } catch (e) {
    throw e;
  }
}

const bigPicture = {
  requestUploadUrl,
  uploadFileToS3,
};

export default bigPicture;
